<template>
  <div v-if="error" class="full-height">
    <v-alert type="error" border="left" text :value="true">
      {{ error }}
    </v-alert>
  </div>
  <div v-else class="all-width">
    <v-card>
      <v-card-text>
        <div class="position-sticky">
          <div class="mb-8 d-flex align-center">
            <span class="title font-weight-bold" label>2.2</span>
            <v-icon class="mx-2">mdi-circle-small</v-icon>
            <div class="subtitle-2">Follow up list</div>
            <v-spacer></v-spacer>
            <v-btn icon><v-icon>mdi-upload</v-icon></v-btn>
          </div>

          <div class="d-flex mb-2 align-center">
            <v-spacer></v-spacer>
            <div>
              <v-chip color="primary" small class="">C</v-chip>
              <span class="ml-2 mr-4">From Past Conserve Assignments</span>
            </div>
            <div>
              <v-chip color="warning" small class="">U</v-chip>
              <span class="ml-2">Manual Uploads</span>
            </div>
          </div>
        </div>

        <div class="mb-4"></div>
        <v-divider></v-divider>
        <v-simple-table dense class="custom-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th scope="#">#</th>
                <th scope="code">Code</th>
                <th scope="area" v-if="!selectedarea">Area</th>
                <th scope="sub-area">Sub Area</th>
                <th scope="procedure">Procedure</th>
                <th scope="type">Type</th>
                <th scope="status">Status</th>
                <th scope="reported-date">Reported Date</th>
                <th scope="process-owner">Process Owner</th>
                <th scope="action">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v, k) in list" :key="k" class="hoverable py-2">
                <td>{{ v.id }}</td>
                <td>{{ v.code }}</td>
                <td>{{ v.area }}</td>
                <td>{{ v.subarea }}</td>
                <td>{{ v.name }}</td>
                <td>
                  <v-chip v-if="v.manual" color="warning" small class=""
                    >U</v-chip
                  >
                  <v-chip v-else small color="primary" class="">C</v-chip>
                </td>
                <td>
                  <v-chip
                    label
                    small
                    color="success"
                    v-if="v.resolved"
                    class="my-1"
                    >Resolved</v-chip
                  >
                  <v-chip label small color="info" v-else class="my-1"
                    >Pending</v-chip
                  >
                </td>
                <td>{{ $nova.formatDate(v.reporteddate) }}</td>
                <td>{{ v.processowner }}</td>
                <td>
                  <v-btn
                    color="primary"
                    small
                    text
                    @click="
                      viewdialog = true;
                      viewarea = v.area;
                      (viewsubarea = v.subarea), (viewverification = v);
                    "
                    >View More</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "assignment_setup_scope",
  data: function () {
    return {
      list: [
        {
          id: "FW1",
          code: "PRO10",
          processowner: "Process Owner Name",
          manual: false,
          name: "Duplication in Vendor Master - PAN, GST, Vendor Code, Vendor Name",
          area: "Procure To Pay [P2P]",
          subarea: "Vendor Master",
          resolved: false,
          reporteddate: "2021-12-31",
        },
        {
          id: "FW2",
          code: "PRO42",
          processowner: "Process Owner Name",
          manual: false,
          name: "Estimated Date earlier than the date of PO",
          area: "Procure To Pay [P2P]",
          subarea: "Purchase Orders",
          resolved: false,
          reporteddate: "2021-12-31",
        },
        {
          id: "FW3",
          code: "PRO86",
          processowner: "Process Owner Name",
          manual: false,
          name: "Raised at Zero Unit Price",
          area: "Procure To Pay [P2P]",
          subarea: "Purchase Orders",
          resolved: true,
          reporteddate: "2021-12-31",
        },
        {
          id: "FW4",
          code: "M5",
          processowner: "Process Owner Name",
          manual: true,
          name: "Custom Procedure name",
          area: "Procure To Pay [P2P]",
          subarea: "custom subarea",
          resolved: false,
          reporteddate: "2021-12-31",
        },
      ],
      loading: false,
      selectedarea: "",
      verificationlist: {},
      arealist: [],
      subarealist: {},
      filelist: {},
      viewdialog: false,
      viewarea: {},
      viewsubarea: {},
      viewverification: {},
      viewtabvalue: "",
    };
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },

  methods: {
    refreshData(full = true) {
      if (full) {
        console.log(full);
      }
      this.loading = false;
      this.selectedarea = "";
      this.verificationlist = {};
      this.arealist = [];
      this.subarealist = {};
      this.filelist = {};
      this.viewdialog = false;
      this.viewarea = {};
      this.viewsubarea = {};
      this.viewverification = {};
      this.viewtabvalue = "";
      this.getData();
    },
    getData() {
      this.error = "";
      this.loading = true;
      this.axios
        .post("/v2/ia/assignment/gettestinglist/" + this.id)
        .then((dt) => {
          if (dt.data.status === "success" && dt.data.data.length > 0) {
            let data = dt.data.data[0];
            this.verificationlist = data.verificationlist;
            this.arealist = data.arealist;
            this.subarealist = data.subarealist;
            this.filelist = data.filelist;
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.counter++;
          this.pageloading = false;
        });
    },
  },
  computed: {
    areadropdownlist: function () {
      let list = [];
      for (const i of this.arealist) {
         list.push({ id: i._id, name: i.name });
      }

        for (const i of this.arealist) {
           list.push({ id: i._id, name: i.name });
        }
      return list;
    },
    getList: function () {
      let list = [];
      for (let i = 0; i < this.arealist.length; i++) {
        const el = this.arealist[i];
        for (let k = 0; k < (this.subarealist[el._id] || []).length; k++) {
          const sel = this.subarealist[el._id][k] || {};
          console.log("here", sel);
          for (let j = 0; j < this.verificationlist[sel._id].length; j++) {
            const vel = this.verificationlist[sel._id][j];
            let nvel = { ...vel };
            nvel.area = el.name;
            nvel.subarea = sel.name;
            list.push(nvel);
          }
        }
      }
      return list;
    },
  },
};
</script>
